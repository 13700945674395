<template>
    <div>
        <rx-navBar :title="title">
        </rx-navBar>
        <!--            业主信息-->
        <div class="part">
            <span class="part-subtitle">客户信息</span>
            <div class="part-inputpart">
                <div class="part-inputpart-row">
                    <div :class="formData.userName.trim()? 'content-have' : 'content-none'"></div>
                    <span class="part-inputpart-row-header">客户</span>
                    <span class="content-divide">|</span>
                    <input v-model="formData.userName" placeholder="请输入客户姓名" >
                </div>
                <div class="part-inputpart-row">
                    <div :class="formData.gender? 'content-have' : 'content-none'"></div>
                    <span class="part-inputpart-row-header">性别</span>
                    <span class="content-divide">|</span>
                    <van-radio-group class="radio_group" direction="horizontal" v-model="formData.gender">
                        <van-radio name="1">
                            <template #icon="props">
                                <span :class="props.checked ? 'isChecked radio_span':'radio_span'">男</span>
                            </template>
                        </van-radio>
                        <span class="content-divide content-divide-position part-inputpart-row-short-graytext">|</span>
                        <van-radio name="0">
                            <template #icon="props">
                                <span :class="props.checked ? 'isChecked radio_span':'radio_span'">女</span>
                            </template>
                        </van-radio>
                    </van-radio-group>
                </div>
                <div class="part-inputpart-row">

                    <span class="part-inputpart-row-header part-inputpart-row-enablenon">年龄</span>
                    <span class="content-divide">|</span>
                    <input type="tel" maxlength="11"
                           v-model="formData.age" placeholder="请输入年龄" >
                </div>
                <div class="part-inputpart-row">
                    <span :class="formData.mobile? 'content-have' : 'content-none' "></span>
                    <span class="part-inputpart-row-header">联系电话</span>
                    <span class="content-divide">|</span>
                    <input type="tel" maxlength="11"
                        v-model="formData.mobile" placeholder="填写联系方式" @blur="checkPhone">
                </div>
                <div class="part-inputpart-row">

                    <span class="part-inputpart-row-header part-inputpart-row-enablenon">微信</span>
                    <span class="content-divide">|</span>
                    <input v-model="formData.wechat" placeholder="请输入微信号">
                </div>
                <div class="part-inputpart-row">
                    <span class="part-inputpart-row-header part-inputpart-row-enablenon">工作单位</span>
                    <span class="content-divide">|</span>
                    <input v-model="formData.address" placeholder="请输入工作单位">
                </div>
                <div class="part-inputpart-row" @click="isUserSourceShow = true">
                    <span :class="formData.potentialUserSourceName?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header ">来源</span>
                    <span class="content-divide">|</span>
                    <span class="part-inputpart-row-graytext"
                        :class="formData.potentialUserSourceName? 'partInputpartRowNormaltext':'part-inputpart-row-graytext' ">{{formData.potentialUserSourceName||'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class=" isUserSourceShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../assets/images/triangle.png">
                </div>


                <div  class="part-inputpart-row" @click="isResourceBelogShow = true">
                    <span :class="resourceBelog?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">潜客类型</span>
                    <span class="content-divide">|</span>
                    <span :class="resourceBelog?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{resourceBelog?resourceBelog.label:'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class=" isResourceBelogShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../assets/images/triangle.png">
                </div>
                <div  class="part-inputpart-row" @click="isKeyNoteShow = true">
                    <span class=" part-inputpart-row-enablenon part-inputpart-row-header">重点需求</span>
                    <span class="content-divide">|</span>
                    <span :class="formData.keyNoteName?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{formData.keyNoteName?formData.keyNoteName:'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                         :class=" isKeyNoteShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                         src="../../assets/images/triangle.png">
                </div>
                <!-- 下拉 -->
                <van-popup v-model="isResourceBelogShow" position="bottom">
                    <van-picker show-toolbar value-key="label" :columns="resourceBelogArr"
                        @cancel="isResourceBelogShow = false" @confirm="selectResourceBelog" />
                </van-popup>
                <div id="recommenderPanel" class="part-inputpart-row" @click="clickRecommenderShow('recommendUserName')">
                    <span class="part-inputpart-row-enablenon part-inputpart-row-header">推荐人</span>
                    <span class="content-divide">|</span>
                    <span class="part-inputpart-row-graytext"
                        :class="formData.recommendUserName?'partInputpartRowNormaltext':'' ">{{formData.recommendUserName ||  '(非必填)'}}</span>
                    <!--                        <span class="part-inputpart-row-right part-inputpart-row-right-rightArrow"></span>-->
                    <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow"
                        src="../../assets/images/youjiantou.png">
                </div>
                <div id="recommenderPanel" class="part-inputpart-row" @click="clickRecommenderShow('maintainUserName')" v-if='addType==2&&mold!="change"'>
                    <span :class="formData.maintainUserName?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">维护人</span>
                    <span class="content-divide">|</span>
                    <span class="part-inputpart-row-graytext"
                        :class="formData.maintainUserName?'partInputpartRowNormaltext':'' ">{{formData.maintainUserName ||  '(必填)'}}</span>
                    <!--                        <span class="part-inputpart-row-right part-inputpart-row-right-rightArrow"></span>-->
                    <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow"
                        src="../../assets/images/youjiantou.png">
                </div>                
            </div>
        </div>
        <!--            需求信息-->
        <div class="part">
            <span class="part-subtitle">需求信息</span>
            <div class="part-inputpart"  v-for="(item,index) in requireList" :key="index">

                <div  class="part-inputpart-row notImport " @click="clickCommunityShow(index)">
                    <span class="part-inputpart-row-header">小区</span>
                    <span class="content-divide">|</span>
                    <span class="part-inputpart-row-graytext"
                        :class="item.roomName?'partInputpartRowNormaltext':'partInputpartRowGraytext'">
                        {{item.roomName|| '请选择'}}
                    </span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow"
                        src="../../assets/images/youjiantou.png">

                </div>
                <div  class="part-inputpart-row" @click="isBusinessCircleShow = true;curIndex = index">
                    <div :class=" item.businessCircleName ? 'content-have' : 'content-none' "></div>
                    <span class="part-inputpart-row-header">商圈</span>
                    <span class="content-divide">|</span>
                    <span class="part-inputpart-row-graytext"
                        :class="item.businessCircleName ?'partInputpartRowNormaltext':'partInputpartRowGraytext'">
                        {{item.businessCircleName || '请选择'}}
                    </span>
                    <img class="part-inputpart-row-right"
                        :class="isBusinessCircleShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../assets/images/triangle.png">

                </div>
                <div class="part-inputpart-row" @click="clickRentTimeShow(index)">
                    <!-- <span class="content-have"></span> -->
                    <span class="part-inputpart-row-header notImport">租期</span>
                    <span class="content-divide">|</span>
                    <span :class="item.rentTimeLabel?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{item.rentTimeLabel||'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class="isRentTimeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../assets/images/triangle.png">
                </div>
                <div id="expectedDatePanel" class="part-inputpart-row" @click="isExpectedDateShow = true;curIndex=index">
                    <span class="part-inputpart-row-enablenon part-inputpart-row-header">预计入住日期</span>
                    <span class="content-divide">|</span>
                    <span class="part-inputpart-row-graytext" :class="item.entryTime ? 'part-inputpart-row-redtext': '' ">
<!--                     | formatDateTime   -->
                        {{item.entryTime | formatDateTime}}
                    </span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                        src="../../assets/images/timepick.png">
                </div>
                <div  class="part-inputpart-row">
                    <span class="part-inputpart-row-header notImport">租金预算</span>
                    <span class="content-divide">|</span>
                    <div class="contentBody">
                        <input type="number" class="priceInput" placeholder="最低价格" v-model="item.lowestPrice">
                        <span>~</span>
                        <input type="number" class="priceInput" placeholder="最高价格" v-model="item.highestPrice">
                    </div>
                    <div class="part-inputpart-row-right">元</div>
                </div>

                <div  class="part-inputpart-row" @click="isRequireTypeShow = true;curIndex = index">
                    <span :class="item.requireTypeName?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">需求类型</span>
                    <span class="content-divide">|</span>
                    <span :class="item.requireTypeName?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{item.requireTypeName||'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class=" isRequireTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../assets/images/triangle.png">
                </div>
                <div class="part-inputpart-textarea">
                    <span class="part-inputpart-row-enablenon part-inputpart-row-header">房屋描述</span>
                    <textarea v-model="item.remarks" placeholder="您遇到什么问题"
                        :class="true ? 'textarea-readonly' : ''"></textarea>
                </div>
                <div class="deleteDemandBtnDiv " v-if="index !=0">
                    <div class="deleteDemandBtn" @click="deleteDemandFun(index)">- 删除需求</div>

                </div>

            </div>
            <div class="addDemandDiv">
            <div class="addDemandBtn" @click="addDemandFun">+ 添加需求</div>

            </div>
        </div>
        <!-- 来源下拉 -->
        <van-popup v-model="isUserSourceShow" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="potentialUserSource"
                        @cancel="isUserSourceShow = false" @confirm="selectUserSource" />
        </van-popup>
        <!-- 租期下拉 -->
        <van-popup v-model="isRentTimeShow" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="rentTimeLong" @cancel="isRentTimeShow = false"
                @confirm="selectRentTime"/>
        </van-popup>
        <!-- 需求类型下拉 -->
        <van-popup v-model="isRequireTypeShow" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="requireTypeArr"
                @cancel="isRequireTypeShow = false" @confirm="selectRequireType" />
        </van-popup>
        <!-- 重点需求下拉 -->
        <van-popup v-model="isKeyNoteShow" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="keyNote"
                        @cancel="isKeyNoteShow = false" @confirm="selectKeyNoteType" />
        </van-popup>
        <!-- 时间下拉 -->
        <van-popup v-model="isExpectedDateShow" position="bottom">
            <van-datetime-picker type="date" title="选择年月日"
                @confirm="expectedDateConfirm" @cancel="isExpectedDateShow = false" />
        </van-popup>
        <!-- 商圈下拉 -->
        <van-popup v-model="isBusinessCircleShow" position="bottom">
            <van-picker show-toolbar value-key="title" title="商圈" :columns="businessCircleArr"
                @cancel="isBusinessCircleShow = false" @confirm="selectBusinessCircle"/>
        </van-popup>
        <div class="bottomBody">
            <div :class="{'part':true,'part-button':true,'part-button-enabled':checkCanSave()}"
                  :disabled="!formData.userName||
                    !formData.mobile||
                    !formData.gender||
                    !formData.potentialUserSource_id||
                    !resourceBelog||!checkListData()||!formData.maintainUserName" @click="save">
                保存
            </div>
        </div>
        <div class="contentFill"></div>
    </div>
</template>

<script>
    import {
        NavBar,
        DatetimePicker,
        Toast,
        RadioGroup,
        Radio,
        Picker,
        Popup
    } from 'vant'
    import {
        queryBaseData,
        addOrUpdatePotentialUser,
        queryBusinessCircle,
        queryPotentialUser
    } from "../../getData/getData";
    import {
        getStaffId,
        responseUtil,
        globaluserId
    } from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import eventBus  from "../../components/rongxun/rx-navBar/envbus.js";
    export default {

        name: 'addPotentialClient',

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Toast.name]: Toast,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            rxNavBar,
        },
        created(){
            this.id = this.$route.query.id
            this.addType=this.$route.query.type
            this.isBD=this.$route.query.isBD
            this.mold=this.$route.query.mold
            this.initData()
        },
        data() {
            return {
                isExpectedDateShow: false,
                ClickTrigger:true,
                isUserSourceShow: false,
                isResourceBelogShow: false,
                isRentTimeShow: false,
                isAddressHave: false,
                isRequireTypeShow:false,
                isBusinessCircleShow:false,
                isKeyNoteShow : false,
                rentTimeLong: [],
                potentialUserSource: [],
                requireTypeArr: [],
                keyNote: [],
                businessCircleArr:[],
                resourceBelogArr: [
                    {
                        value:1,
                        label:'公客'
                    },
                    {
                        value:0,
                        label:'私客'
                    }
                ],
                title:'添加潜客',
                id:'',
                formData: {
                    userName: '',
                    age: '',
                    mobile: '',
                    gender: '',
                    // 重点需求 ui中没有
                    keyNote_id: '',
                    // 来源
                    potentialUserSource_id: '',

                    // 资源所属
                    resourceBelog: 0,
                    recommendUser_id: '',
                    recommendUserName:'',
                    wechat : '',
                    maintainUserName:'',
                    matainStaff_id:'',
                    isBD:''
                },
                // 需求列表
                requireList: [{
                    roomName:'',
                    lowestPrice: '',
                    highestPrice: '',
                    remarks: '',
                    // 当前租期
                    rentTimeLong: '',
                    businessCircleName:'',
                    // entryTime: new Date(),
                    entryTime : '',
                    // 商圈id
                    businessCircle_id:'',
                    // 房间id
                    roomHouse_id:''
                }],
                // 当前来源
                curIndex:'',
                // 当前类型(私客  公客)
                resourceBelog: {
                    value:0,
                    label:'私客'
                },
                //    当前推荐人
                recommendUser: '',
                addType:'',     //从添加BD进如为2,添加潜客进入为1
                mold:''        //是否从修改进入
            }
        },
        filters: {
            formatDateTime(value) {
                if (null == value || '' == value) return '请选择年月日'
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                return y + '-' + MM + '-' + d
            }
        },

        methods: {
            //添加需求
            addDemandFun(){
                this.requireList.push({
                    //小区
                    roomName:'',
                    //商圈
                    businessCircleName :'',
                    //租期
                    rentTimeLabel : '',
                    //预计入住日期
                    entryTime : '',
                    //租金预算
                    lowestPrice: '',
                    highestPrice: '',
                    //需求类型
                    requireTypeName : '',
                    //房屋描述
                    remarks: '',
                    // 当前租期
                    rentTimeLong: '',
                    // entryTime: new Date(),
                    // 商圈id
                    businessCircle_id:'',
                    // 房间id
                    roomHouse_id:'',
                    //微信号
                    wechat : ''
                })
            },
            // 删除需求
            deleteDemandFun(i){
                this.requireList.splice(i,1)
            },
            checkPhone() {
                var reg =
                    /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if ('' != this.formData.mobile) {
                    if (!reg.test(this.formData.mobile)) {
                        responseUtil.alertMsg(this,'手机号格式错误')
                        this.formData.mobile = ''
                    }
                }
            },
            //初始化页面数据
            initData() {
                let that = this
                that.initDicData()
                // 商圈列表
                let initData = {}
                initData.user_id = globaluserId()
                queryBusinessCircle(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data.cityList)
                        let arr = response.data.data.cityList
                        if(arr){
                            // 保持级联动层数一致
                            // arr.forEach(element => {
                            //     if(!element.children){
                            //         element.disabled = true
                            //         element.children = [{
                            //             title:'',
                            //         }]
                            //     }
                            // });
                            // 保持级联动层数一致
                            for(let i=0;i<arr.length;i++){
                                if(!arr[i].children){
                                   let emptyArr = arr.splice(i,1)
                                    arr = arr.concat(emptyArr)
                                    that.$set(arr[arr.length-1],'disabled',true)
                                    that.$set(arr[arr.length-1],'children',[{title:''}])
                                }
                            }
                        }
                        that.$set(that,'businessCircleArr',arr)
                    })
                })
                if(that.id){
                    that.title = '修改潜客'
                    initData.id = that.id
                    queryPotentialUser(initData).then( response => {
                        responseUtil.dealResponse(that, response, () => {
                            console.log(response)
                            that.formData = response.data.data
                            that.requireList = response.data.data.requireList
                            that.callBack()
                        })
                    })
                }

            },

            //初始化字典数据
            initDicData() {
                const that = this
                let initData = {}
                initData.dbName = ['rentTimeLong', 'potentialUserSource', 'requireType', 'keyNote']
                initData.fdName = ['STAFFGENDERMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let lists = response.data.data
                        for (var key in lists) {
                            var values = lists[key];
                            for (var i = 0, item; item = values[i++];) {
                                item.value = item.id || item.dictionaryValue;
                                item.label = item.dictionaryTitle;
                            }
                        }
                        that.staffgendermap = lists.STAFFGENDERMAP
                        that.rentTimeLong = lists.rentTimeLong
                        that.potentialUserSource = lists.potentialUserSource
                        that.requireTypeArr = lists.requireType
                        that.keyNote = lists.keyNote
                        console.log(that.rentTimeLong)
                    })
                })

            },
            selectUserSource(value) {
                this.formData.potentialUserSourceName = value.label;
                this.formData.potentialUserSource_id = value.id
                this.isUserSourceShow = false;
            },
            selectResourceBelog(item) {
                this.resourceBelog = item;
                this.formData.resourceBelog = item.value
                this.isResourceBelogShow = false;
            },
            selectRentTime(value) {
                if(this.curIndex !== "" && this.curIndex >=0){
                    this.$set(this.requireList[this.curIndex],'rentTimeLabel',value.label)
                    this.$set(this.requireList[this.curIndex],'rentTimeLong',value.value)
                }
                this.isRentTimeShow = false;
            },
            selectRequireType(value) {
                if(this.curIndex !== "" &&this.curIndex >=0){
                    this.$set(this.requireList[this.curIndex],'requireType_id',value.id)
                    this.$set(this.requireList[this.curIndex],'requireTypeName',value.label)
                }
                this.isRequireTypeShow = false;
            },
            selectKeyNoteType(item){
                this.formData.keyNote_id = item.value
                this.formData.keyNoteName = item.label
                this.isKeyNoteShow = false;
            },
            selectBusinessCircle(value,index) {
                console.log(value)
                if(this.curIndex !== "" && this.curIndex >=0){
                    this.$set(this.requireList[this.curIndex],'businessCircle_id',this.businessCircleArr[index[0]].children[index[1]].bcId)
                        this.$set(this.requireList[this.curIndex],'businessCircleName',value[1])
                }
                this.isBusinessCircleShow = false;
            },
            clickRecommenderShow(name) {
                let type=''
                if(name=='recommendUserName'){
                    type='5'
                }else if(name=='maintainUserName'){
                    type='6'
                }
                this.$router.push({
                    name: 'referrer',
                    query : {
                        type : type,
                        name: name
                    }
                });
            },
            clickCommunityShow(index) {
                this.curIndex = index
                this.$router.push({
                    name: 'chooseCommunity',
                });
            },
            expectedDateConfirm(value){
                var date = new Date(value)
                this.requireList[this.curIndex].entryTime = date
                this.isExpectedDateShow = false
            },
            clickRentTimeShow(index){
                this.curIndex = index
                this.isRentTimeShow = true
            },
            checkCanSave(){
                let that = this
                if (that.formData.userName&&
                    that.formData.mobile&&
                    that.formData.gender&&
                    that.formData.potentialUserSource_id&&
                    that.resourceBelog&&
                    that.checkListData()
                ){
                    return true
                }
                return false
            },
            callBack(){
                let that = this
                that.resourceBelog = that.setSelectObj(that.resourceBelogArr,that.formData.resourceBelog)
                that.requireList.forEach(item=>{
                    if(item.rentTimeLong){
                        item.rentTimeLabel = that.setSelectObj(that.rentTimeLong,item.rentTimeLong,"value").label
                    }
                    if(item.entryTime){
                        item.entryTime = new Date(item.entryTime)
                    }
                })
            },
            setSelectObj(arr, value, keyName) {
                let obj = {}
                keyName = keyName || 'value'
                arr.forEach((item) => {
                    if (item[keyName] == value) {
                        obj = item
                    }
                })
                return obj
            },
            checkListData(){
                for (let i = 0; i < this.requireList.length; i++) {
                    if(!this.requireList[i].requireType_id||!this.requireList[i].businessCircle_id){
                        return false
                    }
                }
                return true
            },
            save(){
                if (this.ClickTrigger) {
                    this.ClickTrigger = false;
                    let that = this
                    if (!that.checkCanSave()) {
                        return
                    }
                    that.formData.staff_id = getStaffId()
                    that.formData.user_id = globaluserId()
                    that.formData.requireList = that.requireList
                    if(that.formData.matainStaff_id){
                      that.formData.matainStaff_id=that.formData.matainStaff_id//maintainUserName 
                    }         
                    if(that.addType=='2'){
                        that.formData.isBD='1'
                    }
                    console.log(that.formData)
                    addOrUpdatePotentialUser(that.formData).then(function (response) {
                      that.ClickTrigger = true;
                        responseUtil.dealResponse(that, response, () => {
                            if (response.data.code == 0) {
                                responseUtil.alertMsg(that, response.data.msg)
                                that.$router.go(-1)
                            }
                        })
                    })
                }
            }
        },
        beforeRouteLeave:function(to, from, next){
            if (from && to.name != 'chooseCommunity' && to.name != 'referrer')
            {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
                if (this.$vnode && this.$vnode.data.keepAlive)
                {
                    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
                    {
                        if (this.$vnode.componentOptions)
                        {
                            var key = this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                                : this.$vnode.key;
                            var cache = this.$vnode.parent.componentInstance.cache;
                            var keys  = this.$vnode.parent.componentInstance.keys;
                            if (cache[key])
                            {
                                if (keys.length) {
                                    var index = keys.indexOf(key);
                                    if (index > -1) {
                                        keys.splice(index, 1);
                                    }
                                }
                                delete cache[key];
                            }
                        }
                    }
                }
                this.$destroy();
            }
            next();
        },
        activated(){
            let that = this
            eventBus.$on('chooseCommunity', function(data){
                    //赋值给首页的附近医院数据模型
                if(data && data.searchId){
                    if(that.curIndex !== "" && that.curIndex >= 0){
                        this.$set(this.requireList[that.curIndex],'roomName',data.searchInfo)
                        this.$set(this.requireList[that.curIndex],'roomHouse_id',data.searchId)
                        // that.requireList[that.curIndex].roomName = data.searchInfo;
                    }
                }
            }.bind(this));
            eventBus.$on('referrer', function(data){
                debugger
                if(data){
                    if(data.name=='recommendUserName'){
                      this.$set(this.formData,'recommendUserName',data.referrerName)
                      this.$set(this.formData,'recommendUser_id',data.referrerId)
                    }else if(data.name=='maintainUserName'){
                      this.$set(this.formData,'maintainUserName',data.userName)
                      this.$set(this.formData,'matainStaff_id',data.id)
                    }

                        // this.formData.recommendUser_id = data.referrerId
                }
            }.bind(this));
        },
    }
</script>

<style scoped lang="less">

    .content {
        margin-top: 52px;
    }

    .part {
        margin: 15px;
        font-size: 14px;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-weight: 900;
        margin-bottom: 5px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        margin-bottom: 10px;
        /*border: 1px solid red;*/
    }
    .contentFill{
        height: 70px;
    }
    .part-button {
        width: 345px;
        margin: 10px 15px;
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 900;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-hyperlinks {
        float: right;
        color: #3891fa;
        font-size: 12px;
        text-decoration: underline;
        margin-top: 10px;
    }

    .part-inputpart div:last-child {
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-header {
        font-weight: 900;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        background-color: white;
        padding-top: 6px;
        /*padding-bottom: 8px;*/
        /*align-items: flex-end;*/
    }

    .part-inputpart-textarea textarea {
        height: 60px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-row input {
        border: 0;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-dropDownList {
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .content-divide-position {
        padding-top: 4px;
    }

    .part-inputpart-dropDownList-option {
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected {
        color: #ff5d3b;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .partInputpartRowGraytext {
        color: #d8d8d8;
        width: 100%;
        height: 25px;
        line-height: 25px;

    }

    .partInputpartRowNormaltext {
        color: black;
        width: 100%;
    }

    .part-inputpart-row-redtext {
        width: 100%;
        color: #ff5d3b;
    }

    /* .part-inputpart-row-housetype input {
        width: 18px;
        margin-left: 10px;
    } */

    .part-inputpart-row-right {
        float: right;
        margin-right: 20px;
        color: rgba(199, 199, 199, 1)
    }

    .part-inputpart-row-right-rightArrow {
        width: 8px;
    }

    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .part-inputpart-row-button {
        height: 60px;
    }

    .part-row-button {
        margin: 0;
        height: 35px;
        width: 100px;
        border-radius: 8px;
        line-height: 35px;
        font-size: 12px;
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-inputpart-row-short-graytext {
        color: #d8d8d8;
    }

    .radio_group {
        padding-left: 130px;
    }

    .notImport {
        padding-left: 25px;
    }

    .radio_span {
        font-size: 15px;
        color: rgba(153, 153, 153, 1);
        padding: 10px;
        padding-right: 0;

    }

    .isChecked {
        color: tomato !important;
    }

    .contentBody {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .priceInput {
        width: 82px;
        height: 31px;
        box-sizing: border-box;
        padding: 5px;
        border-radius: 5px;
        background: rgba(241, 241, 241, 1);
        color: rgba(34, 34, 34, 1);
        text-align: center;
    }
    .bottomBody{
        border-top: 1px solid #ddd;
        background: #f8f8f8;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 50;
        height:65px
    }
    //删除需求
    .deleteDemandBtnDiv{
        background-color: white;
        /*margin-bottom: 10px;*/
        height: 30px;
        line-height: 35px;
    }
    .deleteDemandBtn,.addDemandBtn{
        width: 25%;
        /*height: 35px;*/
        margin-left: 70%;
        border: none;
        color: white;
        font-size: 13px;
        border-radius: 8px;
        color: red;
        text-decoration:underline;
        /*background-image: linear-gradient(to right,#ffc274, #ff5d3b);*/
    }
</style>
